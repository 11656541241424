import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div class="footer">
      <p>Copyright &#169; GetRandomFont.com 2024</p>
      <p>
        Email us <a href="mailto:paulcapob@gmail.com">here</a>! Learn more about
        us here (coming soon).
      </p>
      <p>
        If you really like this app, support us on{' '}
        <a
          href="https://paypal.me/paulcapdev?country.x=US&locale.x=en_US"
          target="_blank"
          rel="noopener noreferrer"
        >
          PayPal
        </a>
        .
      </p>

      <p>
        Favicon by{' '}
        <a href="https://icons8.com/" target="_blank" rel="noopener noreferrer">
          Icons 8
        </a>
      </p>
    </div>
  );
};

export default Footer;
