import React, { useState, useEffect, useCallback } from 'react';
import './Hero.css';

const Hero = () => {
  const [fonts, setFonts] = useState([]);
  const [randomFont, setRandomFont] = useState(null);
  const [htmlEmbedCode, setHtmlEmbedCode] = useState('');
  const [cssImportCode, setCssImportCode] = useState('');
  const [cssFontFamily, setCssFontFamily] = useState('');

  const updateFontStyles = useCallback((font) => {
    const family = font.family.replace(/\s+/g, '+');
    const fontUrl = `https://fonts.googleapis.com/css2?family=${family}&display=swap`;

    setHtmlEmbedCode(`<link href="${fontUrl}" rel="stylesheet">`);
    setCssImportCode(`@import url('${fontUrl}');`);
    setCssFontFamily(`font-family: '${font.family}', sans-serif;`);

    loadFontStyle(font);
  }, []);

  const getRandomFont = useCallback(
    (fontsData) => {
      if (fontsData && fontsData.length > 0) {
        const index = Math.floor(Math.random() * fontsData.length);
        const font = fontsData[index];
        setRandomFont(font);
        updateFontStyles(font);
      }
    },
    [updateFontStyles]
  );

  const fetchFonts = useCallback(async () => {
    try {
      const response = await fetch('/.netlify/functions/fetchFonts');
      const data = await response.json();
      setFonts(data.items);
      getRandomFont(data.items);
    } catch (error) {
      console.error('Failed to fetch fonts:', error);
    }
  }, [getRandomFont]);

  useEffect(() => {
    fetchFonts();
  }, [fetchFonts]);

  const loadFontStyle = (font) => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${font.family.replace(
      / /g,
      '+'
    )}:wght@400;700&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {});
  };

  return (
    <div className="hero">
      <h1>Random Google Font Generator</h1>
      <div className="hero-text-container">
        <p>
          This is a simple random font generator that generates a random Google
          font. I hated randomly searching through Google Fonts or other font
          services finding a font that I liked. Hopefully this app will save us
          a bit of time looking for cool and original fonts for our apps!
        </p>
      </div>

      {randomFont ? (
        <div className="card">
          <h2>Your font: {randomFont.family}</h2>
          <p>
            <b>SAMPLE: </b>
          </p>
          <p className="sample-text" style={{ fontFamily: randomFont.family }}>
            The quick brown fox jumps over the lazy dog.
          </p>
          <p>Use this HTML to embed in your site:</p>
          <div className="code-container">
            <code>{htmlEmbedCode}</code>
            <button onClick={() => copyToClipboard(htmlEmbedCode)}>
              Copy HTML
            </button>
          </div>
          <p>Add this to your CSS:</p>
          <div className="code-container">
            <code>{cssImportCode}</code>
            <button onClick={() => copyToClipboard(cssImportCode)}>
              Copy CSS @import
            </button>
          </div>
          <p>And apply the font with this CSS:</p>
          <div className="code-container">
            <code>{cssFontFamily}</code>
            <button onClick={() => copyToClipboard(cssFontFamily)}>
              Copy CSS
            </button>
          </div>
          <div className="button-container">
            <button onClick={() => getRandomFont(fonts)}>
              Get Random Font
            </button>
          </div>
        </div>
      ) : (
        <p>Loading fonts...</p>
      )}
    </div>
  );
};

export default Hero;
